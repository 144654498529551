import sanitizeHTML, { IOptions } from 'sanitize-html';
import React, { memo } from 'react';

type TProps = {
  html: string
  className?: string
  style?: React.CSSProperties
  id?: string
} & IOptions;

const SanitizedHTML = ({ html, className = '', id = '', style = {}, ...props }: TProps) => {

  const sanitizedHTML = sanitizeHTML(html, props);

  return (
    <div
      dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
      className={className}
      style={style}
      id={id}
    />
  );

};

export default memo(SanitizedHTML);
